<template>
	<v-overlay v-model="dialog" color="#0E82B9" opacity="0.95">
		<v-container @keydown.enter="confirm">
			<v-row class="pa-10 text-center">
				<div v-if="error" class="col-sm-1 col-md-3"></div>
				<div v-if="error" class="col-sm-10 col-md-6">
					<v-alert class="mt-5 red" border="left" text>
						{{ errorMsg }}
					</v-alert>
				</div>
				<div class="col-12 white--text">
					<strong>{{ $t("and_we_are_almost_there") }}</strong>
					<p>{{ $t("we_sent_code_your_phone") }}</p>
				</div>
				<div class="col-md-12 col-sm-12">
					<input type="text" id="custom-input" :placeholder="$t('enter_received_code')" v-model="otpCode"
						@input="otpCode = $event.target.value.toUpperCase()" class="txt-uppercase" v-uppercase />
				</div>
				<div class="col-12">
					<v-btn color="#F36E21" :disabled="this.otpCode.length < 1" @click="confirm()" depressed class="mt-3"
						min-width="96">
						{{ $t("ok") }}
					</v-btn>

				</div>
			</v-row>

			<!-- Resend SMS -->
			<v-row class="pa-10 text-center" v-if="showResendOtp">
				<v-col md="12" class="white--text">
					<p>{{ $t("resend_sms_heading") }}</p>
					<v-btn color="#F36E21" depressed @click="resendOtpCode()">
						{{ $t("resend_sms") }}
					</v-btn>
				</v-col>
			</v-row>
		</v-container>
	</v-overlay>
</template>

<script>
export default {
	name: "sign-confirm-otp-modal",
	props: {
		dialog: Boolean,
		error: Boolean,
		errorMsg: String,
	},
	data: function () {
		return {
			otpCode: "",
			showResendOtp: false
		};
	},
	watch: {
		dialog() {
			if (this.dialog) {
				setTimeout(() => {
					this.showResendOtp = true;
				}, 30000);
			}
		}
	},
	methods: {
        confirm() {
            console.log("OTP enviado:", this.otpCode);
            this.$emit("submitOtpCode", this.otpCode);
            this.$emit("update:dialog", false); // 🔥 Cerrar el modal OTP
          },
		resendOtpCode() {
			this.$emit("resendOtpCode");
		}
	},
};
</script>
